import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {CodeBlock, vs2015} from 'react-code-blocks';
import {Link} from 'gatsby';

import PageLayout from '../../components/PageLayout';
import SEO from '../../components/seo';
import '../../App.css';
require('typeface-rubik');

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavButton = styled.button`
    background-color: white;
    border-width: 6px;
    color: #000000;
    font-size: 25px;
`;

const NavButtonSelected = styled.button`
    border-width: 6px;
    color: #000000;
    font-size: 25px;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const heading = {
    color: 'rgb(0, 208, 126)',
    cursor: 'pointer'
};

const IndexPage = () => {
    const [screenWidth, setScreenWidth] = useState('70%');
    const [windowWidth, setWindowWidth] = useState(0);
    const [username, setUsername] = useState('harshvardhhan');
    const edited = `${'https://github.com/'}${username}${'/webkit/edit/master/src/config.js'}`;
    const config = `${'https://github.com/'}${username}${'/webkit/edit/master/gatsby-config.js'}`;
    const images = `${'https://github.com/'}${username}${'/webkit/upload/master/src/images'}`;
    const blogPath = "`${__dirname}/content/blog`"
    const assetPath = "`${__dirname}/content/assets`"

    const gatsbyConfig =
    `    module.exports = {
            siteMetadata: {
                title: 'Site Name',
                author: 'Site Author',
                description: 'Default Page Description Goes Here',
                siteUrl: 'https://www.website.com' // Your website URL
            },
            pathPrefix: '/gatsby-paginated-blog',
            plugins: [
                {
                    resolve: 'gatsby-plugin-google-analytics',
                    options: {
                        trackingId: 'UA-TRACKING-ID', // Enter Google Analytics tracking ID
                        head: true,
                        anonymize: true
                    }
                },
                {
                    resolve: 'gatsby-source-filesystem',
                    options: {
                        path: ${blogPath},
                        name: 'blog'
                    }
                },
                {
                    resolve: 'gatsby-source-filesystem',
                    options: {
                        path: ${assetPath},
                        name: 'assets'
                    }
                },
                {
                    resolve: 'gatsby-transformer-remark',
                    options: {
                        plugins: [
                            {
                                resolve: 'gatsby-remark-images',
                                options: {
                                    maxWidth: 590
                                }
                            },
                            {
                                resolve: 'gatsby-remark-responsive-iframe',
                                options: {
                                    wrapperStyle: 'margin-bottom: 1.0725rem'
                                }
                            },
                            'gatsby-remark-prismjs',
                            'gatsby-remark-copy-linked-files',
                            'gatsby-remark-smartypants'
                        ]
                    }
                },
                'gatsby-transformer-sharp',
                'gatsby-plugin-sharp',
                'gatsby-plugin-feed',
                {
                    resolve: 'gatsby-plugin-manifest',
                    options: {
                        name: 'Gatsby Starter Blog', // your site full name
                        short_name: 'GatsbyJS', // your site short name
                        start_url: '/',
                        background_color: '#ffffff',
                        theme_color: '#663399',
                        display: 'minimal-ui',
                        icon: 'content/assets/icon.png'
                    }
                },
                'gatsby-plugin-offline',
                'gatsby-plugin-react-helmet',
                {
                    resolve: 'gatsby-plugin-typography',
                    options: {
                        pathToConfigModule: 'src/utils/typography'
                    }
                },
                {
                    resolve: 'gatsby-plugin-robots-txt',
                    options: {
                        host: 'https://www.website.com', // Your website URL
                        policy: [{userAgent: '*', allow: '/'}]
                    }
                },
                {
                    resolve: 'gatsby-plugin-canonical-urls',
                    options: {
                        siteUrl: 'https://www.website.com' // Your website URL
                    }
                },
                'gatsby-plugin-sitemap'
            ]
        };    
    `;

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth < 480) {
            setScreenWidth('100%');
        }
    }, []);

    return (
        <PageLayout>
            <SEO
                title={'Webkit - Ultimate Starter Kit For Non Technical SEO Consultants'}
                description={'Launch your SEO compliant content website in 2 minutes for free'}
                keywords={[
                    'SEO Analyst',
                    'SEO Technical',
                    'SEO Developer',
                    'SEO Analytics'
                ]}
                image={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Ftoolkit.jpg?alt=media&token=e3b4a777-d719-4a8e-87d1-1dd955fa2978'}
            />
            <Container style={{backgroundColor: '#2ecc7112'}}>
                <Banner windowWidth={windowWidth}/>
            </Container>

            <div className='wrapper'>
                <section className='columns'>
                    <div className='column'>
                        <Link to={'/webkit/launch'}>
                            <NavButton>
                                {'Launch'}
                            </NavButton>
                        </Link>
                    </div>
                    <div className='column'>
                        <NavButtonSelected>
                            {'Modify'}
                        </NavButtonSelected>
                    </div>
                    <div className='column'>
                        <Link to={'/webkit/create'}>
                            <NavButton>
                                {'Create'}
                            </NavButton>
                        </Link>
                    </div>
                </section>
            </div>

            <Container>
                <h1>Modify your site ⚙️</h1>
            </Container>

            <Container style={{backgroundColor: '#2ecc7112', margin: '0 auto', width: screenWidth}}>
                <span style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>Please ensure your github username is present here -</span>

                <div className='form-group'>
                    <span style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>Github Username:</span>
                    &nbsp;
                    <input
                        style={{
                            fontWeight: '600',
                            fontSize: '35px',
                            textAlign: 'center',
                            lineHeight: '1.3',
                            width: screenWidth
                        }}
                        className='form-field'
                        type='text'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder='domain.tld'
                    />
                </div>
            </Container>

            <div style={{margin: '0 auto', width: screenWidth}}>
                <h2>
                    1. Modify your homepage by editing the
                    <a
                        style={heading}
                        onClick={() => window.open(edited)}
                    >
                        {' '}{'config file'}{' '}
                    </a>.
                    Save the changes by clicking on <a style={heading}> Commit changes.</a>
                </h2>
                <img
                    style={{
                        width: '100%',
                        borderImageWidth: '2px',
                        borderColor: '#00000'
                    }}
                    src={require('../../images/modify-config.png')}
                />
                <h3>Upload images with name <a style={heading}> image-1.png</a>, <a style={heading}>image-2.png</a>, <a style={heading}>image-3.png</a>  at this <a style={heading} onClick={() => window.open(images)}>link</a></h3>
            </div>

            <div style={{margin: '0 auto', width: screenWidth}}>
                <h2>
                    2. Modify your application settings by editing this
                    <a
                        style={heading}
                        onClick={() => window.open(config)}
                    >
                        {' '}{'config file'}{' '}
                    </a>. modify the code which have <a style={heading}>{`//`}</a> on them.
                    Save the changes by clicking on <a style={heading}> Commit changes</a>
                </h2>
                <CodeBlock
                    language={'javascript'}
                    text={gatsbyConfig}
                    showLineNumbers={true}
                    theme={vs2015}
                    wrapLines={true}
                    codeBlock={true}
                />
            </div>
            <br/>

        </PageLayout>
    );
};

const Banner = ({windowWidth}) => {
    if (windowWidth > 480) {
        return (
            <>
                <h1 style={{textAlign: 'center', fontSize: '5vw', lineHeight: '1.5', fontWeight: '600'}}>
                    Setting Up and Launching<br/>Webkit Site
                </h1>
            </>
        );
    } else {
        return (
            <>
                <h1 style={{fontSize: '9vw', textAlign: 'center', lineHeight: '1.3'}}>
                    Setting Up and Launching<br/>Webkit Site
                </h1>
            </>
        );
    }
};

export default IndexPage;
